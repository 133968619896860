// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, Em, P, H4 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		background: ${props.isSolution ? props.theme.bc1 : props.theme.bc2};
		color: ${props.isSolution ? props.theme.bc2 : props.theme.bc1};
		padding-top: 6rem;
		padding-bottom: calc(6rem + 3.6rem);

		${breakup.large`
			${props.theme.vh50}
			min-height: 36rem;
			max-height: 48rem;
			padding-bottom: 6rem;
		`}

		> div {
			height: 100%;
		}
	`
);

const sharedStyles = css`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex: 1;
`;

export const Caption = styled.div`
	${sharedStyles}
`;

export const CaptionMain = styled(Em)`
	color: inherit;
`;

export const CaptionSub = styled(P)`
	margin: 0.6rem 0 0 0;
	color: inherit;
`;

export const Number = styled.p(
	(props) => css`
		${sharedStyles}

		font-size: 9.6rem;
		font-weight: ${props.theme.reg};
		font-family: ${props.theme.heading};
		line-height: 1;
		color: inherit;
		margin: 2.4rem 0;

		${breakup.medium` font-size: 10.8rem; `}
		${breakup.large`
			font-size: 12rem;
			margin: 0;
		`}

		${props.isSolution &&
		css`
			display: inline-block;
			background: linear-gradient(
				to right,
				${props.theme.bc4} 0%,
				${props.theme.bc5} 100%
			);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			${breakup.large` display: flex; `}
		`}
	`
);

export const Content = styled.div`
	${sharedStyles}

	${breakup.large`
		align-items: flex-start;
		text-align: left;
	`}
`;

export const Text = styled(H4)`
	margin: 0 0 1.2rem 0;
	color: inherit;
`;

export const Label = styled(Em)`
	color: inherit;
`;

export const Button = styled.div`
	${sharedStyles}
	align-items: flex-end;
`;
