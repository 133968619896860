// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@parts/Seo';
import loadable from '@loadable/component';
import Hero from '@parts/Hero';
import Introduction from '@parts/Introduction';
import Statistics from '@parts/Statistics';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { action } from 'mobx';
import { headerState } from '@states/header';

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

// Component
// ------
const BackgroundVideo = loadable(() => import('@parts/BackgroundVideo'));
const HowItWorks = loadable(() => import('@parts/HowItWorks'));
const UseCases = loadable(() => import('@parts/UseCases'));
const Features = loadable(() => import('@parts/Features'));
const Quality = loadable(() => import('@parts/Quality'));
const DigitalPhysical = loadable(() => import('@parts/DigitalPhysical'));
const Privacy = loadable(() => import('@parts/Privacy'));
const Principles = loadable(() => import('@parts/Principles'));

const IndexPage = ({ data, transitionStatus }) => {
	useEffect(() => {
		const setHeader = action(() => {
			headerState.noBg = false;
		});

		setHeader();
	}, []);

	useEffect(() => {
		const Splitting = require('splitting');

		Splitting({
			target: '[data-splitting]',
			by: 'chars',
			key: null,
		});
	}, []);

	return (
		<>
			<Seo
				title={data.content.seo.title}
				desc={data.content.seo.desc}
				image={data.content.seo.image}
			/>

			<BackgroundVideo
				url={data.content.heroVideoUrl}
				image={data.content.heroVideoFallbackImage}
			/>

			<Hero
				title={data.content.heroTitleMain}
				subtitle={data.content.heroTitleSub}
				excerpt={data.content.heroExcerpt}
				ctaLabel={data.global.label}
				ctaLink={data.global.link}
			/>

			<Introduction
				clientLogosTitle={data.content.clientLogosTitle}
				clientLogos={data.content.clients}
				image={data.content.introductionImage.fluid}
				titleSub={data.content.introductionTitleSub}
				titleMain={data.content.introductionTitleMain}
				text={data.content.introductionText}
			/>

			<Statistics
				problems={data.content.problemStatistic}
				solutions={data.content.solutionStatistic}
			/>

			<HowItWorks
				title={data.content.howItWorksTitle}
				subTitle={data.content.howItWorksSubTitle}
				slider={data.content.howItWorksSlider}
			/>

			<UseCases
				title={data.content.useCaseTitle}
				subTitle={data.content.useCaseSubTitle}
				useCases={data.content.useCases}
			/>

			<Features
				title={data.content.featuresTitle}
				subTitle={data.content.featuresSubTitle}
				features={data.content.features}
			/>

			<Quality
				text={data.content.qualityText}
				subTitle={data.content.qualitySubTitle}
				titleSub={data.content.qualityTitleSub}
				titleMain={data.content.qualityTitleMain}
				image={data.content.qualityImage}
				ctaLabel={data.global.label}
				ctaLink={data.global.link}
			/>

			<DigitalPhysical sections={data.content.digitalPhysical} />

			<Privacy
				subTitle={data.content.privacySubTitle}
				title={data.content.privacyTitle}
				text={data.content.privacyText}
				buttonDesc={data.content.privacyButtonDesc}
			/>

			<Principles
				subTitle={data.content.principlesSubTitle}
				title={data.content.principlesTitle}
				text={data.content.principlesText}
				sections={data.content.principles}
			/>

			<Footer />
		</>
	);
};

export default IndexPage;

// GraphQL
// ------
export const query = graphql`
	query HomeQuery {
		global: datoCmsGlobal {
			label: globalCtaLabel
			link: globalCtaLink
		}

		content: datoCmsHome {
			# SEO
			seo: seoMeta {
				title
				desc: description
				image {
					url
				}
			}

			# Hero
			heroTitleSub
			heroTitleMain
			heroExcerpt
			heroVideoUrl
			heroVideoFallbackImage {
				url
				fluid(maxHeight: 900, maxWidth: 1440) {
					...GatsbyDatoCmsFluid_noBase64
				}
			}
			# Client Logos
			clientLogosTitle
			clients: clientsLogos {
				oId: originalId
				alt
				fixed(height: 120, width: 120) {
					...GatsbyDatoCmsFixed_tracedSVG
				}
			}

			# Introduction
			introductionTitleSub
			introductionTitleMain
			introductionText
			introductionImage {
				fluid(maxHeight: 600, maxWidth: 800) {
					...GatsbyDatoCmsFluid_noBase64
				}
			}

			#  Statistics
			problemStatistic {
				number
				text
				label
			}
			solutionStatistic {
				number
				text
				label
			}

			# How it works
			howItWorksTitle
			howItWorksSubTitle
			howItWorksSlider {
				heading
				text
				uiSnippet {
					fluid(maxHeight: 1440, maxWidth: 1440) {
						...GatsbyDatoCmsFluid_noBase64
					}
				}
			}

			# Use Cases
			useCaseTitle
			useCaseSubTitle
			useCases {
				heading
				caption
				image {
					fluid(maxHeight: 600, maxWidth: 400) {
						...GatsbyDatoCmsFluid_noBase64
					}
				}
			}

			# Features
			featuresTitle
			featuresSubTitle
			features {
				heading
				text
				icon {
					url
				}
			}

			# Quality
			qualityText
			qualitySubTitle
			qualityTitleSub
			qualityTitleMain
			qualityImage {
				fluid(maxHeight: 800, maxWidth: 1440) {
					...GatsbyDatoCmsFluid_noBase64
				}
				fixed(height: 469, width: 795) {
					...GatsbyDatoCmsFixed_tracedSVG
				}
			}

			# Digital & Physical
			digitalPhysical {
				heading
				subHeading
				text
				image {
					fluid(maxHeight: 1440, maxWidth: 1440) {
						...GatsbyDatoCmsFluid_noBase64
					}
				}
			}

			# Privacy
			privacyText
			privacyTitle
			privacySubTitle
			privacyButtonDesc
			principlesText
			principlesTitle
			principlesSubTitle
			principles {
				image {
					fluid(maxHeight: 1440, maxWidth: 1440) {
						...GatsbyDatoCmsFluid_noBase64
					}
				}
				title
				excerpt
			}
		}
	}
`;
