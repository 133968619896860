// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1, P } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		${props.theme.vh}

		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;

		background: transparent;

		${breakup.large`
			min-height: calc(var(--vh, 1vh) * 100);
		`}
	`
);

export const Content = styled.div(
	(props) => css`
		color: ${props.theme.bc2};
		text-align: center;
	`
);

export const Title = styled(H1)`
	margin-bottom: 2.4rem;
	${breakup.large` margin-bottom: 3.6rem; `}
`;

export const Details = styled(P)`
	margin-top: 2.4rem;
	padding: 0 4.8rem;

	${breakup.large`
        margin-top: 3.6rem;
        padding: 0;
    `}
`;

export const TickerPos = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
`;
