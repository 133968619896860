// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H3, P, Em, Div } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)((props) => css``);

const sharedStyles = css`
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1;
`;

export const Picture = styled.div`
	position: relative;
	${sharedStyles}

	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`;

export const ImgJacket = styled.div`
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
`;

export const Content = styled.div`
	${sharedStyles}

	margin: 2.4rem 0 0 0;
	${breakup.medium` margin: 3.6rem 0 0 0; `}
	${breakup.large` margin: 0; `}
`;

export const Heading = styled(H3)`
	margin: 0 0 2.4rem 0;
	${breakup.large` margin: 0 0 3.6rem 0; `}
`;

export const Text = styled(P)``;



export const LogoTitle = styled(Em)`
	text-align: center;
	margin: 0 0 2.4rem 0;
`;


export const LogoGrid = styled(Div)`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	justify-content: center;

	${breakup.medium`
		grid-template-columns: repeat(3, 1fr);
		width: 75%;
		margin: 0 auto;
	`}

	${breakup.large`
		grid-template-columns: repeat(6, 1fr);
		width: 100%;
	`}

`;

export const LogoContent = styled(Div)(props => css`
	border-top: 1px solid ${props.theme.bc1};
	margin-top: ${props.theme.marS};

	${breakup.medium`
		margin-top: ${props.theme.marM};
	`}

	${breakup.large`
		margin-top: ${props.theme.marL};
	`}
`);


export const Logo = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;

	.gatsby-image-wrapper {
		width: 12rem !important;
		height: 12rem !important;

		img {
			object-fit: contain !important;
		}
	}
`