// Imports
// ------
import React from 'react';
import Icon from '@icons';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const NextButton = ({ className, isSolution }) => (
	<Jacket type='button' className={className} isSolution={isSolution}>
		<Icon type='arrow right' />
	</Jacket>
);

export default React.memo(NextButton);
