// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		.swiper-pagination {
			${breakup.large` display: none; `}
		}

		.swiper-pagination-bullets {
			bottom: 6rem;
		}

		.swiper-pagination-bullet {
			width: 0.6rem;
			height: 0.6rem;
		}

		.problem {
			.swiper-pagination-bullet {
				background-color: ${props.theme.bc1};
			}
		}

		.solution {
			.swiper-pagination-bullet {
				background-color: ${props.theme.bc2};
			}
		}
	`
);
