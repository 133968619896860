// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';
import { Row, Column } from '@waffl';

// Styles
// ------
import { Jacket, ImgJacket, Picture, Content, Heading, Text, LogoTitle, LogoGrid, LogoContent, Logo } from './styles';

// Component
// ------
const Introduction = ({ clientLogosTitle, clientLogos, titleSub, titleMain, text, image }) => {
	const logosTitle = clientLogosTitle ? clientLogosTitle : `Trusted by`;

	return (
		<Jacket bg pad borderBottom>
			{/* NOTE • Image & Text */}
			<Row isExpanded isEqual>
				<Column small={12} medium={10} pushOnMedium={1} large={5} mpad>
					<Picture data-trigger>
						<ImgJacket style={{ overflow: 'hidden' }}>
							<Img fluid={image} alt='Introduction Image' />
						</ImgJacket>
					</Picture>
				</Column>

				<Column
					small={12}
					medium={10}
					pushOnMedium={1}
					large={4}
					pushOnLarge={2}
					mpad
				>
					<Content data-trigger>
						<Heading>
							{titleMain}
							<span>{` ` + titleSub}</span>
						</Heading>

						<Text>{text}</Text>
					</Content>
				</Column>
			</Row>

			{/* NOTE • Client Logos */}
			<Row isExpanded isEqual>
				<Column small={12} large={10} pushOnLarge={1} mpad>
					<LogoContent padTop>
						<LogoTitle>{logosTitle}</LogoTitle>
						<LogoGrid>
							{clientLogos.map(({ oId, alt, fixed }) => (
								<Logo key={oId}>
									<Img
										fixed={fixed}
										alt={alt ? alt : `Client Logo`}
									/>
								</Logo>
							))}
						</LogoGrid>
					</LogoContent>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Introduction;
