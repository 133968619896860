// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
const sharedStyles = css`
	z-index: 1;
	width: 4.8rem;
	height: 4.8rem;
	border-radius: 4.8rem;

	${breakup.xlarge`
        width: 6rem;
        height: 6rem;
        border-radius: 6rem;
    `}
`;

export const Jacket = styled.button(
	(props) => css`
		${sharedStyles}
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;

		&:before,
		&:after {
			${sharedStyles}
			content: "";
			position: absolute;
			top: 50%;
			left: 50%;
			transition: all 0.5s ${props.theme.bezzy};
		}

		&:before {
			border: 1px solid
				${props.isSolution ? props.theme.bc2 : props.theme.bc1};
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}

		&:after {
			background: ${props.isSolution ? props.theme.bc2 : props.theme.bc1};
			opacity: 0;
			transform: translate(-50%, -50%) scale(0);
		}

		svg {
			position: relative;
			z-index: 3;
			width: 1.6rem;
			height: 1.6rem;
			fill: ${props.isSolution ? props.theme.bc2 : props.theme.bc1};
			transition: all 0.5s ${props.theme.bezzy};
		}

		&:hover {
			cursor: pointer;

			&:before {
				opacity: 0;
				transform: translate(-50%, -50%) scale(0);
			}

			&:after {
				opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			}

			svg {
				fill: ${props.isSolution ? props.theme.bc1 : props.theme.bc2};
			}
		}
	`
);
