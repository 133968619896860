// Imports
// ------
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper';
import SingleStat from './SingleStat';

// Styles
// ------
import '@styles/swiper.css';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import { Jacket } from './styles';

// Component
// ------
SwiperCore.use([Navigation, Pagination, EffectFade]);

const Statistics = ({ problems, solutions }) => {
	const options = {
		loop: true,
		speed: 500,
		effect: 'fade',
		pagination: { clickable: false },
	};

	const problemOptions = {
		...options,
		navigation: {
			nextEl: '.next-problem',
		},
	};

	const solutionOptions = {
		...options,
		navigation: {
			nextEl: '.next-solution',
		},
	};

	return (
		<Jacket bg>
			<Swiper {...problemOptions} className='problem'>
				{problems.map(({ number, text, label }) => (
					<SwiperSlide key={text}>
						<SingleStat
							isProblem
							text={text}
							number={number}
							label={label}
							nextClass='next-problem'
						/>
					</SwiperSlide>
				))}
			</Swiper>

			<Swiper {...solutionOptions} className='solution'>
				{solutions.map(({ number, text, label }) => (
					<SwiperSlide key={text}>
						<SingleStat
							isSolution
							text={text}
							number={number}
							label={label}
							nextClass='next-solution'
						/>
					</SwiperSlide>
				))}
			</Swiper>
		</Jacket>
	);
};

export default Statistics;
