// Imports
// ------
import React, { useEffect, useRef } from 'react';
import Button from '@parts/Button';
import Ticker from '@parts/Ticker';
import anime from 'animejs/lib/anime.es.js';
import { Row, Column } from '@waffl';
import { headerSize } from '@states/headerSize';
import { tickerSize } from '@states/tickerSize';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Content, Title, Details, TickerPos } from './styles';

// Component
// ------
const Hero = ({ title, subtitle, excerpt, ctaLabel, ctaLink }) => {
	const container = useRef();

	useEffect(() => {
		anime({
			targets: container.current,
			opacity: [0, 1],
			duration: 3000,
		});
	});

	return (
		<Jacket ref={container}>
			<Row
				isExpanded
				style={{
					paddingTop: headerSize.height,
					paddingBottom: tickerSize.height,
				}}
			>
				<Column small={12} pushOnMedium={1} medium={10} mpad>
					<Content>
						<Title
							isLight
							data-splitting
							className='title-animation'
						>
							<div>{title}</div>
							<em>{subtitle}</em>
						</Title>
						<Button
							isExternal
							isLight
							to={ctaLink}
							hasIcon='arrow right'
						>
							{ctaLabel}
						</Button>
						<Details isLight>{excerpt}</Details>
					</Content>
				</Column>
			</Row>

			<TickerPos>
				<Ticker />
			</TickerPos>
		</Jacket>
	);
};
export default observer(Hero);
