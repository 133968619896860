// Imports
// ------
import React from 'react';
import NextButton from './NextButton';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

// Styles
// ------
import {
	Jacket,
	Caption,
	CaptionMain,
	CaptionSub,
	Number,
	Content,
	Button,
	Text,
	Label,
} from './styles';

// Component
// ------
const Statistics = ({
	number,
	text,
	label,
	isSolution,
	isProblem,
	nextClass,
}) => {
	const bp = useBreakpoint();

	return (
		<Jacket isSolution={isSolution}>
			<Row isExpanded isEqual>
				{/* NOTE • Caption */}
				<Column small={12} medium={10} pushOnMedium={1} large={2} mpad>
					<Caption data-trigger>
						<CaptionMain small>The</CaptionMain>
						<CaptionMain>
							{isProblem && `Problem`}
							{isSolution && `Solution`}
						</CaptionMain>
						<CaptionSub isAlt>
							{isProblem && `with digital`}
							{isSolution && `is technology enabled`}
						</CaptionSub>
					</Caption>
				</Column>

				{/* NOTE • Number */}
				<Column small={12} medium={10} pushOnMedium={1} large={3} mpad>
					<Number isSolution={isSolution} data-trigger>
						{number}
					</Number>
				</Column>

				{/* NOTE • Text */}
				<Column small={12} medium={10} pushOnMedium={1} large={4} mpad>
					<Content data-trigger>
						<Text>{text}</Text>
						<Label small>{label}</Label>
					</Content>
				</Column>

				{bp.large ? null : (
					<Column small={12} pushOnLarge={1} large={1} mpad>
						<Button>
							<NextButton
								isSolution={isSolution}
								className={nextClass}
							/>
						</Button>
					</Column>
				)}
			</Row>
		</Jacket>
	);
};

export default React.memo(Statistics);
